import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import topimg from './top.jpg';
import logoimg from './logo.png';
import telimg from './tel.png';

ReactDOM.render(
  <React.StrictMode>
      <p class="header-discription h6">相模原市 リフォーム 建明工務店</p>

	<div className="row justify-content-between no-gutters">
		<div className="col-12 col-sm-7">
			<img className="img-fluid" alt="logo" src={logoimg}></img>
		</div>
		<div className="col-12 col-sm-5">
			<div className="row no-gutters justify-content-end">
				<img className="img-fluid" alt="tel" src={telimg}></img>
			</div>
		</div>
	</div>

    <div className="jumbotron-fluid">
		<div className="justify-content-center no-gutters">
			<img className="img-fluid" alt="top" src={topimg}></img>
		</div>
    </div>

	<div className="contents">
		<div className="d-flex flex-column justify-content-center h3">
			<div className="align-self-center">相模原市で５０年、建築業を営んでまいりました。</div>
			<div className="align-self-center">在来工法、2×4工法...</div>
			<div className="align-self-center">なんでもお気軽にお問い合わせください。</div>
			<div className="align-self-center">お見積もりは無料です。</div>
		</div>
	</div>

	<footer className="footer mt-auto py-3 text-muted h6 bg-light">
		<div className="container">
			Copyright© 2020 kenmei koumuten
		</div>
	</footer>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
